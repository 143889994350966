<template>
  <div v-if="!isLoading">
    <teleport to="body">
      <job-person-spec-modal
        @closeModal="toggleJobSpecModal(false)"
        v-if="jobSpecModalOpen"
        :jobSpecification="getHiringRequest.job_specification"
        :personSpecification="getHiringRequest.person_specification"
      />
      <base-confirmation-dialog
        :show="confirmationDialog"
        title="Are you sure?"
      >
        <base-button
          v-if="!declineOfferCheck"
          @click="confirmOffer"
          :animateButton="animateButton"
        >
          Confirm
        </base-button>
        <base-button
          v-else
          @click="declineOffer"
          :animateButton="animateButton"
        >
          Decline Offer
        </base-button>
        <base-button
          v-show="!animateButton"
          mode="cancel"
          @click="closeConfirmationDialog"
          >Cancel</base-button
        >
      </base-confirmation-dialog>
      <edit-hiring-request
        v-if="editDialog"
        :getHiringRequestData="getHiringRequest"
        :animateButton="animateButton"
        @close-modal="editRequest"
        @submit-modal="submitHiringRequestEdit"
      />
    </teleport>
    <transition appear>
      <div class="">
        <div class="w-1/2 float-left min-w-sm mx-auto my-5 text-darkGrey">
          <staff-request-form
            :title="'Current Offer'"
            :showOfferButtons="true"
            :showEditButton="false"
            :showStatus="false"
            @confirm-offer="confirmDialog"
            @cancel-offer="confirmDialogCancel"
            @edit-request="editRequest"
            @offer-ammendments="setAmmendments"
            :data="getHiringRequest"
            :animateButton="animateButton"
            @toggleJobSpecModal="toggleJobSpecModal(true)"
          />
        </div>
        <div class="flex flex-col">
          <div class="flex items-center gap-x-3 pl-2">
            <h1 class="font-bold">Ammendment Fields:</h1>
          </div>

          <div class="flex w-full mb-2">
            <div
              v-if="ammendedOffers.length === 0 || createAmmendCheck === true"
              class="
                bg-white
                rounded-xl
                w-full
                flex flex-col
                mx-3
                justify-around
                shadow-md
              "
            >
              <div class="p-2 flex items-center justify-between text-darkGrey">
                <div>
                  <div class="flex items-center gap-x-3">
                    <h3 class="font-bold">Offer Information:</h3>
                  </div>
                </div>
              </div>
              <div class="flex pl-4">
                <label for="startDate" class="w-3/12">Starting Date:</label>
                <input
                  name="startDate"
                  type="date"
                  class="w-9/12 rounded-lg border bg-white md:w-11/12 h-10 pl-2"
                  v-model="ammendObject.joining_date"
                />
              </div>
              <div class="flex pl-4">
                <label for="startDate" class="w-3/12">Salary Amount:</label>
                <input
                  name="startDate"
                  type="number"
                  class="w-9/12 rounded-lg border bg-white md:w-11/12 h-10 pl-2"
                  v-model="ammendObject.amount"
                />
              </div>
              <div>
                <base-button
                  :mode="'pause'"
                  class="float-right"
                  @click="ammendOfferFunc"
                  >Ammend Offer</base-button
                >
              </div>
            </div>
          </div>
          <div key="refreshAmendments">
            <div
              v-for="ammendment in ammendedOffers.reverse()"
              :key="ammendment.id"
              class="flex w-full mb-2"
              :class="
                ammendment?.is_active === 0
                  ? 'opacity-80 cursor-not-allowed'
                  : ''
              "
            >
              <div
                class="
                  bg-white
                  rounded-xl
                  w-full
                  flex flex-col
                  mx-3
                  justify-around
                  shadow-md
                "
              >
                <div
                  class="p-2 flex items-center justify-between text-darkGrey"
                >
                  <div class="flex flex-row items-center gap-x-3">
                    <h3 class="font-bold">Offer Information:</h3>
                  </div>
                  <div class="flex flex-row items-center gap-x-3">
                    <h3 class="font-bold">
                      (Date Created:{{
                        new Date(ammendment?.created_at).toLocaleDateString(
                          "en-GB"
                        )
                      }})
                    </h3>
                  </div>
                </div>
                <div class="flex pl-4">
                  <label for="startDate" class="w-3/12">Starting Date:</label>
                  <input
                    name="startDate"
                    type="date"
                    class="
                      w-9/12
                      rounded-lg
                      border
                      bg-white
                      md:w-11/12
                      h-10
                      pl-2
                    "
                    :disable="ammendment?.is_active === 0 ? true : false"
                    v-model="ammendment.joining_date"
                  />
                </div>
                <div class="flex pl-4">
                  <label for="startDate" class="w-3/12">Salary Amount:</label>
                  <input
                    name="startDate"
                    type="number"
                    class="
                      w-9/12
                      rounded-lg
                      border
                      bg-white
                      md:w-11/12
                      h-10
                      pl-2
                    "
                    :disable="ammendment?.is_active === 0 ? true : false"
                    v-model="ammendment.amount"
                  />
                </div>
                <div v-if="ammendment?.is_active === 1 && hideButtons === true">
                  <base-button
                    :mode="'cancel'"
                    class="float-right"
                    @click="declineAmmendment(ammendment.id)"
                    >Decline</base-button
                  >
                  <base-button
                    class="float-right"
                    @click="acceptAmmendment(ammendment.id)"
                    >Accept</base-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
  
  <script>
import jobPersonSpecModal from "@/components/modal/jobPersonSpecModal.vue";
import staffRequestForm from "@/components/ui/baseComponents/Recruitment/staffRequestForm.vue";
import baseConfirmationDialog from "@/components/ui/baseComponents/baseConfirmationDialog.vue";
import editHiringRequest from "@/components/modal/editHiringRequest.vue";
export default {
  components: {
    staffRequestForm,
    jobPersonSpecModal,
    baseConfirmationDialog,
    editHiringRequest,
  },
  async created() {
    this.isLoading = true;
    await this.fetchHiringRequest(this.$route.params.manageVacancy);
    this.isLoading = false;
  },
  data() {
    return {
      jobSpecModalOpen: false,
      isLoading: false,
      animateButton: false,
      confirmationDialog: false,
      declineOfferCheck: false,
      offerID: null,
      editDialog: false,
      offerPayload: {
        practice: null,
        hiring_request: null,
        user: null,
        work_pattern: null,
        amount: null,
      },
      ammendObject: {
        offer: null,
      },
      ammendedOffers: [],
      offerApplicant: {},
      createAmmendCheck: false,
      refreshAmendments: 0,
      hideButtons: true,
    };
  },
  methods: {
    confirmDialog() {
      this.confirmationDialog = true;
    },
    confirmDialogCancel(data) {
      this.declineOfferCheck = true;
      this.offerID = data;
      this.confirmationDialog = true;
    },
    editRequest() {
      this.editDialog = !this.editDialog;
    },
    async confirmOffer() {
      this.animateButton = true;
      this.offerPayload.practice = this.getHiringRequest.practice_id;
      this.offerPayload.hiring_request = this.getHiringRequest.id;
      this.offerPayload.user = Number(this.$route.params.userId);
      this.offerPayload.work_pattern =
        this.getHiringRequest.work_patterns[0].id;
      this.offerPayload.amount = 100;
      try {
        await this.$store.dispatch("REnewHire/createOffer", this.offerPayload);
        this.$router.push({
          name: "re-manage-vacancy",
          params: { manageVacancy: this.$route.params.manageVacancy },
          query: { success: "true" },
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
        this.$router.push({
          name: "re-manage-vacancy",
          params: { manageVacancy: this.$route.params.manageVacancy },
          query: { success: "false" },
        });
      }
    },
    async declineOffer() {
      console.log("DECLINE");
      this.animateButton = true;
      this.offerPayload.offer = Number(this.offerID);
      this.offerPayload.status = 0;
      this.offerPayload.amount = this.getHiringRequest.starting_salary;
      try {
        await this.$store.dispatch("REnewHire/updateOffer", this.offerPayload);
        this.$router.push({
          name: "re-manage-vacancy",
          params: { manageVacancy: this.$route.params.manageVacancy },
          query: { success: "true" },
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
        this.$router.push({
          name: "re-manage-vacancy",
          params: { manageVacancy: this.$route.params.manageVacancy },
          query: { success: "false" },
        });
      }
    },
    async submitHiringRequestEdit(data) {
      this.animateButton = true;
      console.log(data);
      // this.offerPayload.practice = this.getHiringRequest.practice_id;
      // this.offerPayload.hiring_request = this.getHiringRequest.id;
      // this.offerPayload.user = Number(this.$route.params.userId);
      // this.offerPayload.work_pattern =
      //   this.getHiringRequest.work_patterns[0].id;
      // this.offerPayload.amount = this.getHiringRequest.starting_salary;
      try {
        await this.$store.dispatch("REnewHire/updateHiringRequest", data);
        this.animateButton = false;
        this.$router.push({
          name: "re-manage-vacancy",
          params: { manageVacancy: this.$route.params.manageVacancy },
          query: { success: "true" },
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
        this.$router.push({
          name: "re-manage-vacancy",
          params: { manageVacancy: this.$route.params.manageVacancy },
          query: { success: "false" },
        });
      }
    },
    closeConfirmationDialog() {
      this.confirmationDialog = false;
    },
    toggleJobSpecModal(value) {
      this.jobSpecModalOpen = value;
    },
    async fetchHiringRequest(requestFormId) {
      try {
        await this.$store.dispatch("HQnewHire/fetchHiringRequest", {
          hiring_request: requestFormId,
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    setAmmendments(value) {
      this.ammendedOffers = value?.offers[0]?.amendments;
      this.offerApplicant = value;
      for(let i=0; i < this.ammendedOffers.length; i++){
        if(this.ammendedOffers[i].is_active === 0){
          this.createAmmendCheck = true;
        }
        else{
          this.createAmmendCheck = false;
        }
      }
    },
    async ammendOfferFunc() {
      this.ammendObject.offer = this.offerApplicant?.offers[0]?.id;
      try {
        const response = await this.$store.dispatch(
          "REnewHire/ammendOffer",
          this.ammendObject
        );
        this.ammendedOffers = response.data.offer.amendments;
        this.refreshAmendments++;
        this.animateButton = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
      this.createAmmendCheck = false;
      this.hideButtons = true;
    },
    async declineAmmendment(id) {
      const ammendObject = {
        amendment: id,
        status: 0,
      };
      await this.$store.dispatch("REnewHire/updateAmmendment", ammendObject);
      this.createAmmendCheck = true;
      this.hideButtons = false;
    },
    async acceptAmmendment(id) {
      const ammendObject = {
        amendment: id,
        status: 1,
      };
      await this.$store.dispatch("REnewHire/updateAmmendment", ammendObject);
      this.$router.push({
          name: "re-offers-inbox",
        });
    },
  },
  computed: {
    getHiringRequest() {
      return this.$store.getters["HQnewHire/getHiringRequest"];
    },
  },
};
</script>
  